<template>
  <div class="target-box">
    <h3>企业人资管理现状  ·  situation</h3>
    <p>当前大多数企业在人力资源管理方面存在以下问题</p>
    <div class="target-point">
      <img src="/static/img/men-back1.jpg"/>
      <div class="point _A">
        <div class="bubble">
          A
          <div class="txt-box">
            <h3>缺乏数字化人力资源体系</h3>
            <p>企业缺乏信息化支持造成时间、成本浪费<br/>大量业务数据需要低效的人工完成</p>
          </div>
        </div>
      </div>
      <div class="point _B">
        <div class="bubble">
          B
          <div class="txt-box">
            <h3>缺乏运营数据分析及预测</h3>
            <p>企业缺乏人力资源基础数据无法看清组织现状<br/>无法进行未来的预测分析与人资优化配置</p>
          </div>
        </div>
      </div>
      <div class="point _C">
        <div class="bubble">C
          <div class="txt-box">
            <h3>缺乏激活组织创新的手段</h3>
            <p>企业缺乏技术手段重塑员工体验<br/>无法通过管理创新方式激发组织价值创造</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.target-box{
  box-sizing: border-box;
  padding: 50px 0 0;
  color: #383838;
  position: relative;
  background: url(/static/img/men-back1.jpg) no-repeat bottom center;
  background-size: 100% auto;
  >h3{
    text-transform: uppercase;
    font-size: 18px;
    color: #383838;
    font-weight: bold;
    text-align: center;
    padding: 0 10px;
  }
  >p{
    font-size: 14px;
    font-weight: 300;
    margin-top: 15px;
    text-align: center;
    padding: 0 10px;
  }
  .target-point{
    position: relative;
    padding-top: 485px;
    >img{
      width: 100%;
      display: block;
    }
    .txt-box{
      position: absolute;
      width: 70vw;
      box-sizing: border-box;
      >h3{
        font-size: 15px;
        color: #383838;
        font-weight: bold;
      }
      >p{
        font-size: 13px;
        color: #656464;
        font-weight: 300;
        line-height: 1.8;
        margin-top: 5px;
      }
    }
    .point{
      position:absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #E2E2E2;
      .bubble{
        position: absolute;
        width: 38px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 800;
        color: #fff;
        background-color: #F23D49;
        border: 1px solid #EAEAEA;
        border-radius: 50%;
        font-style: normal;
        line-height: 1;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        &::before{
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border: 4px solid #fff;
          border-radius: 50%;
        }
      }
      &::after{
        content: '';
        position: absolute;
        left: 2.5px;
        width: 1px;
        bottom: 0;
        background-color: #E2E2E2;
        z-index: 0;
      }
      &._A{
        left: 8.8vw;
        bottom: 32.6vw;
        .bubble{
          top: -302px;
          .txt-box{
            top: 0;
            right: -12px;
            transform: translateX(100%);
          }
        }
        &::after{
          top: -302px;
        }
      }
      &._B{
        left: 50%;
        transform: translateX(-50%);
        bottom: 22vw;
        .bubble{
          top: -140px;
          .txt-box{
            text-align: center;
            width: 80vw;
            top: 0;
            left: 50%;
            transform: translate(-50%, calc( -100% - 10px ));
          }
        }
        &::after{
          top: -140px;
        }
      }
      &._C{
        right: 8.93vw;
        bottom: 18vw;
        .bubble{
          top: -492px;
          .txt-box{
            text-align: right;
            top: 0;
            right: 12px;
            transform: translateX(-38px);
          }
        }
        &::after{
          top: -492px;
        }
      }
    }
    
  }
}
</style>