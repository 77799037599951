<template>
  <div class="value-box">
    <h3>创造价值  ·  VALUE</h3>
    <div class="value-item" v-for="(item,index) in list" :key="index">
      <h3><span>0{{index+1}}</span>-{{item.name}}</h3>
      <p>{{item.remark}}</p>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      list:[
        {name:'决策智慧化',remark:'透过动态数据挖掘，提供智能化决策辅助为企业人才与业务发展的匹配提速、提质。'},
        {name:'动态薪酬核算',remark:'提供复杂薪酬核算引擎，满足企业复杂薪酬管理需求。'},
        {name:'数字化人力资源',remark:'涵盖员工档案、入转调离、电子工资条、培训中心、薪酬绩效、个税申报等一站式人资服务。'},
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.value-box{
  padding: 50px 15px;
  color: #383838;
  >h3{
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }
  .value-item{
    border: 1px solid #E2E2E2;
    padding: 20px;
    background-color: #fff;
    &:not(:last-child){
      margin-bottom: 20px;
    }
    &:nth-child(2n-1){
      background-color: #F6FBFF;
    }
    >h3{
      font-size: 17px;
      color: #383838;
      font-weight: bold;
      line-height: 1.5;
      >span{
        color: #E80B10;
      }
    }
    >p{
      font-size: 13px;
      color: #383838;
      line-height: 2;
      margin-top: 10px;
      text-align: justify;
    }
  }
}
</style>