<template>
  <div class="unify-box">
    <h3>数智人资·云效  ·  i-HRDP</h3>
    <h4>企业组织人效管理与创新解决方案</h4>
    <p>建立以人为本的企业人力资源数字化管理体系，通过数字化创新方式重塑员工体验，从而激发组织的创新能力与创造力。</p><br/>
    <p>逐步形成以企业人才大脑为核心的画像库，为企业积累宝贵的人力资源要素数据，助推企业技术创新能力的进一步提升。</p><br/>
    <p>解决方案聚焦企业人才、薪酬及数据，全面涵盖企业数字化员工服务、数字化薪酬管理、数字化人才画像三大应用场景，着力打造企业人力资源数字化创新的新高地。</p>
    <button @click="openService">立即咨询了解更多详情</button>
  </div>
</template>
<style lang="less" scoped>
.unify-box{
  padding: 60px 30px;
  background: url(/static/img/men-back2.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  >h3{
    // text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  >h4{
    font-size: 15px;
    font-weight: 300;
    margin-top: 15px;
    text-align: center;
    padding-bottom: 70px;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      bottom: 30px;
      left: 50%;
      width: 15px;
      height: 2px;
      background-color: #fff;
      transform: translateX(-50%);
    }
  }
  >p{
    font-size: 15px;
    font-weight: 300;
    line-height: 2.5;
    text-align: justify;
  }
  >button{
    margin-top: 60px;
    width: 100%;
    border: 0 none;
    background: none;
    appearance: none;
    border-radius: 3px;
    line-height: 42px;
    font-size: 16px;
    font-weight: bold;
    background-color: #E80B10;
    color: #fff;
  }
}
</style>