<template>
  <div class="advantage-box">
    <h3>解决方案构成   ·  component</h3>
    <p>数智人资·云效解决方案主要由以下四部分构成</p>
    <ul>
      <li :class="{'on':type==1}" @click="type=1">人事基础</li>
      <li :class="{'on':type==2}" @click="type=2">员工中心</li>
      <li :class="{'on':type==3}" @click="type=3">人资管理</li>
      <li :class="{'on':type==4}" @click="type=4">数智洞察</li>
    </ul>
    <div class="advantage-box-content">
      <transition name="slide-fade" mode="out-in">
        <div v-if="type==1" class="txt-info">
          <h3>人事基础</h3>
          <img src="/static/img/men-img3.jpg"/>
          <p>提供员工入转调离人事全流程管理，基础功能涵盖：智能考勤、员工出差、入转调离、培训中心、福利关怀、绩效薪酬、个税申报、问卷调查等。</p>
        </div>
        <div v-else-if="type==2" class="txt-info">
          <h3>员工中心</h3>
          <img src="/static/img/men-img4.jpg"/>
          <p>为员工打造个人专属空间、多维度个人数字档案，员工中心功能涵盖：个人档案、工作轨迹、成长历程、资质证书、电子工资条、培训历程等。</p>
        </div>
        <div v-else-if="type==3" class="txt-info">
          <h3>人资管理</h3>
          <img src="/static/img/men-img2.jpg"/>
          <p>为企业人力资源提供人力资源数字化管理体系，人资管理功能涵盖：组织管理、员工管理、薪酬管理、培训管理、配置管理、流程审批等。</p>
        </div>
        <div v-else-if="type==4" class="txt-info">
          <h3>数智洞察</h3>
          <img src="/static/img/men-img1.jpg"/>
          <p>为企业决策层提供多纬度运营数据分析，数智洞察功能涵盖：员工盘点、人才画像、人岗位匹配、行为留痕、效能分析、胜任力分析、绩效预测、技能雷达等。</p>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type:1,
    }
  },
}
</script>
<style lang="less" scoped>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity .3s
}

.slide-fade-enter-from, .slide-fade-leave-to {
  opacity: 0
}
.advantage-box{
  padding: 45px 0;
  background-color: #F6F6F8;
  color: #383838;
  >h3{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  >p{
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    margin-top: 15px;
  }
  >ul{
    display: flex;
    align-items: center;
    margin-top: 30px;
    height: 40px;
    border-bottom: 1px solid #E8E8E8;
    padding: 0 15px;
    >li{
      font-size: 15px;
      color: #383838;
      position: relative;
      line-height: 38px;
      &.on::after{
        content: '';
        position: absolute;
        left: -10px;
        right: -10px;
        bottom: 1px;
        height: 2px;
        background-color: #E80B10;
      }
    }
  }
  .advantage-box-content{
    min-height: 400px;
    .txt-info{
      padding: 30px 22.5px;
      color: #383838;
      >h3{
        font-size: 18px;
        line-height: 1.8;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
      }
      >img{
        width: 100%;
        display: block;
        margin-top: 25px;
      }
      >p{
        font-size: 15px;
        line-height: 2;
        font-weight: 300;
        margin-top: 25px;
        text-align: justify;
      }
    }
  }
}
</style>